import logo from './logo.svg';
import './App.css';
import io from 'socket.io-client';
import { useState, useEffect } from 'react';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
const socket = io('YellowishLiquidEmbed.bananasquares.repl.co:8099', { transports: ['websocket'] });

function App() {
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [inputs, setInputs] = useState({});
  const [boxes, setBoxes] = useState({r: false,g:false,b:false,y:false});
  const [canChoose, setCanChoose] = useState([]);
  useEffect(() => {
    socket.on('connect', () => {
      setIsConnected(true);
    });
    socket.on('disconnect', () => {
      setIsConnected(false);
    })
    socket.on('success', (msg) => {
      console.log(msg);
      setCanChoose(msg);
      console.log(canChoose);
      toast.success("Filled!");
    })
    socket.on('picked', (msg) => {
      setCanChoose(msg[1]);
      toast.success("Picked " + msg[0])
    })

    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('picked');
      socket.off('success');
    }
  }, [])
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
  }

  function sendPick() {
    let str = '';
    if(!boxes.r) {
      str = str + 'r';
    }
    if(!boxes.g) {
      str = str + 'g';
    }
    if(!boxes.b) {
      str = str + 'b';
    }
    if(!boxes.y) {
      str = str + 'y';
    }
    console.log(boxes);
    console.log(str);
    socket.emit('pick', { cant: str });
    
  }
  function handleSubmit(event)  {
    event.preventDefault();
    socket.emit("fill",inputs)
  }
  function handleCheckbox(event) {
    const name = event.target.name;
    const value = event.target.checked;
    console.log(name + value.toString())
    setBoxes(values => ({...values, [name]: value}))
  }
  if (isConnected) {
    return (

      <div className="h-100 w-full flex items-center justify-center bg-teal-lightest font-sans">
        <div className="bg-white rounded shadow p-6 m-4 w-full lg:w-3/4 lg:max-w-lg">
          <div className='mb-4 items-center justify-center flex flex-col'>
            <h1 className='flex text-grey-darkest text-xl font-semibold items-center'>House Picker</h1>
              <form className='rounded px-8 pt-6 pb-8 mb-4 items-center flex-col flex' onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2" for="r">
                    Red
                  </label>
                  <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="r" type="number" placeholder="Red amount"onChange={handleChange} name={'r'}/>
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2" for="b">
                    Blue
                  </label>
                  <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="b" type="number" placeholder="Blue amount" onChange={handleChange}name={'b'}/>
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2" for="g">
                    Green
                  </label>
                  <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="g" type="number" placeholder="Green amount" onChange={handleChange} name={'g'}/>
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2" for="y">
                    Yellow
                  </label>
                  <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="y" type="number" placeholder="Yellow amount" onChange={handleChange} name={'y'}/>
                </div>
                <div className="mb-4">
                  <input className="flex-no-shrink p-2 border-2 rounded text-teal-400 border-teal-400 hover:text-white hover:bg-teal-400" id="y" type="submit" placeholder="Yellow amount" onChange={handleChange} />
                </div>
              </form>
              <div className="mb4 flex flex-col items-center justify-center">
                <h1>Left to choose: {canChoose}</h1>
              </div>
              <div class="flex justify-center">
  <div>
  <h1>Can choose:</h1>
    <div class="form-check">
      <input class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-red-600 checked:border-red-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" value="" id="flexCheckDefault" onChange={handleCheckbox} name='r'/>
      <label class="form-check-label inline-block text-gray-800" for="flexCheckDefault">
        Red
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-green-600 checked:border-green-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" value="" id="flexCheckDefault" onChange={handleCheckbox} name='g'/>
      <label class="form-check-label inline-block text-gray-800" for="flexCheckDefault">
        Green
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" value="" id="flexCheckDefault" onChange={handleCheckbox} name='b'/>
      <label class="form-check-label inline-block text-gray-800" for="flexCheckDefault">
        Blue
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-yellow-600 checked:border-yellow-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" value="" id="flexCheckDefault" onChange={handleCheckbox} name='y'/>
      <label class="form-check-label inline-block text-gray-800" for="flexCheckDefault">
        Yellow
      </label>
    </div>
    
  </div>
</div>
            <button className="flex-no-shrink p-2 border-2 rounded text-teal-400 border-teal-400 hover:text-white hover:bg-teal-400" onClick={sendPick}> Roll!</button>
          </div>
        </div>
        <ToastContainer/>
      </div>
    )
  }
  return (
    <div className="App">
      <h1>Failed to connect to backend!</h1>
    </div>
  );
}

export default App;
